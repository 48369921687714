import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NodeListQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['String']>;
  nodeId?: Types.InputMaybe<Types.Scalars['String']>;
  pagination: Types.Pagination;
  filter: Array<Types.SearchFilter> | Types.SearchFilter;
  isInTranslation?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type NodeListQuery = { __typename?: 'Query', nodeList: { __typename?: 'SearchResult', nodes: { __typename?: 'NodeResult', count: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean }, edges: Array<{ __typename?: 'SearchNodeEdge', cursor: string, node: { __typename?: 'SearchNode', id: string, code: string, listName?: string | null, parent: string, parentCode: string, hierarchy: string, disabled: boolean, firstNodeStatus?: string | null, writeRoles?: Array<string> | null, order?: string | null, contentTypeGroupStatus?: Array<{ __typename?: 'ContentTypeGroupStatus', code: string, status: Types.CtgStatus, publishedAt?: string | null }> | null, breadcrumb?: Array<{ __typename?: 'BreadcrumbNode', id: string, code: string, listName?: string | null }> | null } }> }, currentNode?: { __typename?: 'SearchNode', code: string, hierarchy: string, id: string, listName?: string | null, parent: string, parentCode: string, readRoles?: Array<string> | null, writeRoles?: Array<string> | null, contentTypeGroupStatus?: Array<{ __typename?: 'ContentTypeGroupStatus', code: string, status: Types.CtgStatus, publishedAt?: string | null }> | null } | null }, breadcrumb: Array<{ __typename?: 'BreadcrumbNode', id: string, code: string, listName?: string | null }> };

export type GetPublishedNodeVersionsQueryVariables = Types.Exact<{
  node: Types.Scalars['String'];
  hierarchy: Types.Scalars['String'];
}>;


export type GetPublishedNodeVersionsQuery = { __typename?: 'Query', getPublishedNodeVersions: Array<{ __typename?: 'PublishedNode', id?: string | null, updatedAt: string, updatedBy: string }> };

export type GetPublishedContentQueryVariables = Types.Exact<{
  node: Types.Scalars['String'];
  hierarchy: Types.Scalars['String'];
  contentTypeGroup?: Types.InputMaybe<Types.Scalars['String']>;
  version?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPublishedContentQuery = { __typename?: 'Query', getPublishedContent: { __typename?: 'PublishedNode', id?: string | null, updatedAt: string, updatedBy: string, contentTypeGroups?: Array<{ __typename?: 'PublishedContentTypeGroup', contentTypeGroup: string, contents: Array<{ __typename?: 'NodeContentO', contentTypeGroup: string, contentTypeGroupIndex: number, contentType: string, repeatable?: boolean | null, type: string, inherited: boolean, value?: Array<{ __typename?: 'NodeContentValueO', languageIsoCode: string, value: Array<any | null>, code?: string | null, measure?: string | null, translate?: boolean | null }> | null }> }> | null } };

export type GetHierarchyHistoryQueryVariables = Types.Exact<{
  hierarchy: Types.Scalars['String'];
  node: Types.Scalars['String'];
  pagination: Types.Pagination;
}>;


export type GetHierarchyHistoryQuery = { __typename?: 'Query', hierarchyHistory: { __typename?: 'HierarchyHistoryResult', totalCount: number, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, endCursor?: string | null }, objectSchema: { __typename?: 'ObjectSchema', contentTypeGroups?: Array<{ __typename?: 'ContentTypeGroup', code: string, repeatable: boolean, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }>, contentTypes?: Array<{ __typename?: 'ContentType', code: string, type: string, multiLanguage: boolean, multiValue: boolean, hidden?: boolean | null, name: Array<{ __typename?: 'NameObject', languageIsoCode: string, value: string }> }> | null }> | null }, edges: Array<{ __typename?: 'HierarchyHistoryEdge', cursor: string, node: { __typename?: 'HierarchyHistoryNode', eventId: string, user: string, eventName: string, disabled: boolean, parent: string, code: string, timestamp: string, contents: Array<{ __typename?: 'NodeContentO', contentTypeGroup: string, contentType: string, repeatable?: boolean | null, type: string, inherited: boolean, value?: Array<{ __typename?: 'NodeContentValueO', languageIsoCode: string, value: Array<any | null>, code?: string | null, measure?: string | null }> | null }> } }> } };

export type PublishAllHierarchiesMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type PublishAllHierarchiesMutation = { __typename?: 'Mutation', publishHierarchies?: { __typename?: 'ObjectSchemaMutationResponse', code: string, message: string } | null };

export type EventCreatedSubscriptionVariables = Types.Exact<{
  hierarchyId?: Types.InputMaybe<Types.Scalars['String']>;
  nodeId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type EventCreatedSubscription = { __typename?: 'Subscription', eventCreated: { __typename?: 'SubscriptionEvent', edits: Array<{ __typename?: 'Edit', contentTypeGroup: string, contentType: string, value: any, type: string }> } };

export type ExportJobFinishedSubscriptionVariables = Types.Exact<{
  jobId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type ExportJobFinishedSubscription = { __typename?: 'Subscription', exportJobFinished: { __typename?: 'ExportJobFinishedInfo', filename?: string | null } };


export const NodeListDocument = gql`
    query NodeList($id: String, $nodeId: String, $pagination: Pagination!, $filter: [SearchFilter!]!, $isInTranslation: Boolean) {
  nodeList(
    id: $id
    nodeId: $nodeId
    pagination: $pagination
    filter: $filter
    isInTranslation: $isInTranslation
  ) {
    nodes {
      count
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          code
          listName
          parent
          parentCode
          hierarchy
          disabled
          contentTypeGroupStatus {
            code
            status
            publishedAt
          }
          firstNodeStatus
          breadcrumb {
            id
            code
            listName
          }
          writeRoles
          order
        }
      }
    }
    currentNode {
      code
      hierarchy
      id
      listName
      parent
      parentCode
      contentTypeGroupStatus {
        code
        status
        publishedAt
      }
      readRoles
      writeRoles
    }
  }
  breadcrumb(hierarchyId: $id, nodeId: $nodeId) {
    id
    code
    listName
  }
}
    `;

/**
 * __useNodeListQuery__
 *
 * To run a query within a React component, call `useNodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      nodeId: // value for 'nodeId'
 *      pagination: // value for 'pagination'
 *      filter: // value for 'filter'
 *      isInTranslation: // value for 'isInTranslation'
 *   },
 * });
 */
export function useNodeListQuery(baseOptions: Apollo.QueryHookOptions<NodeListQuery, NodeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NodeListQuery, NodeListQueryVariables>(NodeListDocument, options);
      }
export function useNodeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NodeListQuery, NodeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NodeListQuery, NodeListQueryVariables>(NodeListDocument, options);
        }
export type NodeListQueryHookResult = ReturnType<typeof useNodeListQuery>;
export type NodeListLazyQueryHookResult = ReturnType<typeof useNodeListLazyQuery>;
export type NodeListQueryResult = Apollo.QueryResult<NodeListQuery, NodeListQueryVariables>;
export const GetPublishedNodeVersionsDocument = gql`
    query GetPublishedNodeVersions($node: String!, $hierarchy: String!) {
  getPublishedNodeVersions(node: $node, hierarchy: $hierarchy) {
    id
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useGetPublishedNodeVersionsQuery__
 *
 * To run a query within a React component, call `useGetPublishedNodeVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedNodeVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedNodeVersionsQuery({
 *   variables: {
 *      node: // value for 'node'
 *      hierarchy: // value for 'hierarchy'
 *   },
 * });
 */
export function useGetPublishedNodeVersionsQuery(baseOptions: Apollo.QueryHookOptions<GetPublishedNodeVersionsQuery, GetPublishedNodeVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedNodeVersionsQuery, GetPublishedNodeVersionsQueryVariables>(GetPublishedNodeVersionsDocument, options);
      }
export function useGetPublishedNodeVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedNodeVersionsQuery, GetPublishedNodeVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedNodeVersionsQuery, GetPublishedNodeVersionsQueryVariables>(GetPublishedNodeVersionsDocument, options);
        }
export type GetPublishedNodeVersionsQueryHookResult = ReturnType<typeof useGetPublishedNodeVersionsQuery>;
export type GetPublishedNodeVersionsLazyQueryHookResult = ReturnType<typeof useGetPublishedNodeVersionsLazyQuery>;
export type GetPublishedNodeVersionsQueryResult = Apollo.QueryResult<GetPublishedNodeVersionsQuery, GetPublishedNodeVersionsQueryVariables>;
export const GetPublishedContentDocument = gql`
    query GetPublishedContent($node: String!, $hierarchy: String!, $contentTypeGroup: String, $version: String) {
  getPublishedContent(
    node: $node
    hierarchy: $hierarchy
    contentTypeGroup: $contentTypeGroup
    version: $version
  ) {
    id
    contentTypeGroups {
      contentTypeGroup
      contents {
        contentTypeGroup
        contentTypeGroupIndex
        contentType
        repeatable
        type
        inherited
        value {
          languageIsoCode
          value
          code
          measure
          translate
        }
      }
    }
    updatedAt
    updatedBy
  }
}
    `;

/**
 * __useGetPublishedContentQuery__
 *
 * To run a query within a React component, call `useGetPublishedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublishedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublishedContentQuery({
 *   variables: {
 *      node: // value for 'node'
 *      hierarchy: // value for 'hierarchy'
 *      contentTypeGroup: // value for 'contentTypeGroup'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetPublishedContentQuery(baseOptions: Apollo.QueryHookOptions<GetPublishedContentQuery, GetPublishedContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublishedContentQuery, GetPublishedContentQueryVariables>(GetPublishedContentDocument, options);
      }
export function useGetPublishedContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublishedContentQuery, GetPublishedContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublishedContentQuery, GetPublishedContentQueryVariables>(GetPublishedContentDocument, options);
        }
export type GetPublishedContentQueryHookResult = ReturnType<typeof useGetPublishedContentQuery>;
export type GetPublishedContentLazyQueryHookResult = ReturnType<typeof useGetPublishedContentLazyQuery>;
export type GetPublishedContentQueryResult = Apollo.QueryResult<GetPublishedContentQuery, GetPublishedContentQueryVariables>;
export const GetHierarchyHistoryDocument = gql`
    query GetHierarchyHistory($hierarchy: String!, $node: String!, $pagination: Pagination!) {
  hierarchyHistory(hierarchy: $hierarchy, node: $node, pagination: $pagination) {
    totalCount
    pageInfo {
      hasNextPage
      endCursor
    }
    objectSchema {
      contentTypeGroups {
        code
        repeatable
        name {
          languageIsoCode
          value
        }
        contentTypes {
          code
          type
          multiLanguage
          multiValue
          hidden
          name {
            languageIsoCode
            value
          }
        }
      }
    }
    edges {
      cursor
      node {
        eventId
        user
        eventName
        disabled
        parent
        code
        timestamp
        contents {
          contentTypeGroup
          contentType
          repeatable
          type
          inherited
          value {
            languageIsoCode
            value
            code
            measure
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetHierarchyHistoryQuery__
 *
 * To run a query within a React component, call `useGetHierarchyHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHierarchyHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHierarchyHistoryQuery({
 *   variables: {
 *      hierarchy: // value for 'hierarchy'
 *      node: // value for 'node'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useGetHierarchyHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetHierarchyHistoryQuery, GetHierarchyHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHierarchyHistoryQuery, GetHierarchyHistoryQueryVariables>(GetHierarchyHistoryDocument, options);
      }
export function useGetHierarchyHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHierarchyHistoryQuery, GetHierarchyHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHierarchyHistoryQuery, GetHierarchyHistoryQueryVariables>(GetHierarchyHistoryDocument, options);
        }
export type GetHierarchyHistoryQueryHookResult = ReturnType<typeof useGetHierarchyHistoryQuery>;
export type GetHierarchyHistoryLazyQueryHookResult = ReturnType<typeof useGetHierarchyHistoryLazyQuery>;
export type GetHierarchyHistoryQueryResult = Apollo.QueryResult<GetHierarchyHistoryQuery, GetHierarchyHistoryQueryVariables>;
export const PublishAllHierarchiesDocument = gql`
    mutation PublishAllHierarchies {
  publishHierarchies {
    code
    message
  }
}
    `;
export type PublishAllHierarchiesMutationFn = Apollo.MutationFunction<PublishAllHierarchiesMutation, PublishAllHierarchiesMutationVariables>;

/**
 * __usePublishAllHierarchiesMutation__
 *
 * To run a mutation, you first call `usePublishAllHierarchiesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishAllHierarchiesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishAllHierarchiesMutation, { data, loading, error }] = usePublishAllHierarchiesMutation({
 *   variables: {
 *   },
 * });
 */
export function usePublishAllHierarchiesMutation(baseOptions?: Apollo.MutationHookOptions<PublishAllHierarchiesMutation, PublishAllHierarchiesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishAllHierarchiesMutation, PublishAllHierarchiesMutationVariables>(PublishAllHierarchiesDocument, options);
      }
export type PublishAllHierarchiesMutationHookResult = ReturnType<typeof usePublishAllHierarchiesMutation>;
export type PublishAllHierarchiesMutationResult = Apollo.MutationResult<PublishAllHierarchiesMutation>;
export type PublishAllHierarchiesMutationOptions = Apollo.BaseMutationOptions<PublishAllHierarchiesMutation, PublishAllHierarchiesMutationVariables>;
export const EventCreatedDocument = gql`
    subscription EventCreated($hierarchyId: String, $nodeId: String) {
  eventCreated(hierarchyId: $hierarchyId, nodeId: $nodeId) {
    edits {
      contentTypeGroup
      contentType
      value
      type
    }
  }
}
    `;

/**
 * __useEventCreatedSubscription__
 *
 * To run a query within a React component, call `useEventCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEventCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventCreatedSubscription({
 *   variables: {
 *      hierarchyId: // value for 'hierarchyId'
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useEventCreatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<EventCreatedSubscription, EventCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<EventCreatedSubscription, EventCreatedSubscriptionVariables>(EventCreatedDocument, options);
      }
export type EventCreatedSubscriptionHookResult = ReturnType<typeof useEventCreatedSubscription>;
export type EventCreatedSubscriptionResult = Apollo.SubscriptionResult<EventCreatedSubscription>;
export const ExportJobFinishedDocument = gql`
    subscription ExportJobFinished($jobId: String) {
  exportJobFinished(jobId: $jobId) {
    filename
  }
}
    `;

/**
 * __useExportJobFinishedSubscription__
 *
 * To run a query within a React component, call `useExportJobFinishedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useExportJobFinishedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportJobFinishedSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useExportJobFinishedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ExportJobFinishedSubscription, ExportJobFinishedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ExportJobFinishedSubscription, ExportJobFinishedSubscriptionVariables>(ExportJobFinishedDocument, options);
      }
export type ExportJobFinishedSubscriptionHookResult = ReturnType<typeof useExportJobFinishedSubscription>;
export type ExportJobFinishedSubscriptionResult = Apollo.SubscriptionResult<ExportJobFinishedSubscription>;